@import '../../variable.scss';

.contactPageContainer {
    padding: 42px 24px 24px 24px;
    .title {
      font-size: 36px;
    }
    .contactCardContainer {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        .ant-card {
            border-radius: 5px;
        }
    }
  
  }
  
  @media only screen and (max-width: 880px) {
    .contactPageContainer {
      padding: 24px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .contactPageContainer {
      padding: 24px;
    }
  }