@import '../../variable.scss';
.mainActivityContainer {
  display: flex;
  justify-content: space-around;
  height: 90vh;
  background-image: linear-gradient(to bottom, rgba(245, 246, 252, 0.52), black),
    url('../../assets/images/cover.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  .mainContent {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .mainTitle {
      font-size: 40px;
      color: white;
      margin-bottom: 10px
    }
    .mainAction {
      @include buttons;
      background-color: $e-dark;
      color: white;
      cursor: pointer;
    }
    .mainAction:hover {
      background-color: $e-dark-hover;
    }
    .mainAction:focus {
      outline: solid $e-light 2px
    }
  }
  .news {
    @include centralize();
  }
}


@media only screen and (max-width: 880px) {
  .mainActivityContainer {
    .mainContent {
      padding: 24px;
      .mainTitle {
        font-size: 28px;
      }
    }
    .news {
      padding: 24px;
    }
  }
}


@media only screen and (max-width: 480px) {
  .mainActivityContainer {
    flex-direction: column;
    height: 150vh;
    .mainContent {
      height: 90vh;
      width: 100%;
      justify-content: center;
      align-items: center;
      .mainTitle {
        font-size: 32px;
        text-align: center;
      }
    }
    .news {
    height: 60vh;
    width: 100%;
    background-color: $light;
    }
  }
}
