@import '../../variable.scss';

.commonPageContainer {
  padding: 42px 24px 24px 24px;
  .title {
    font-size: 36px;
  }
  .description {
    text-align: justify;
  }
}

@media only screen and (max-width: 880px) {
  .commonPageContainer {
    padding: 24px;
  }
}

@media only screen and (max-width: 480px) {
  .commonPageContainer {
    padding: 24px;
  }
}
