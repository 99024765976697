@import '../variable.scss';

.headerContainer {
  display: flex;
  padding: 5px;
  background-color: $e-dark;
  height: 100px;
  align-items: center;
  width: 100%;
  z-index: 9;
  svg {
    width: 80px;
    margin-left: 8px;
  }
  img {
    width: 80px;
  }
  .title {
    margin: 0;
    margin-left: 20px;
    color: $e-light;
    font-family: $font-header;
    font-size: 32px;
  }
}

@media only screen and (max-width: 880px) {
  .headerContainer {
    height: 80px;
    .title {
      font-size: 24px;
    }
  }
}

@media only screen and (max-width: 480px) {
  .headerContainer {
    .title {
      font-size: 16px;
      word-wrap: break-word;
      font-family: $font-regular;
    }
  }
}
