@import './variable.scss';

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #b8b8b8;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $e-dark;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(1, 22, 1);
}

.ant-modal-content {
  .ant-modal-body {
    background-color: $background;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    a {
      text-decoration: underline;
      color: $e-dark;
      font-style: bold;
    }
  }
  .ant-modal-footer {
    background-color: $light;
    button {
      color: $light;
      background-color: $e-dark;
      border-color: $e-dark;
    }
    button:hover {
      border-color: $e-light;
    }
  }
}
