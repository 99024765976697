@import '../../variable.scss';

.activityContainer {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    padding: 24px;

    .title {
      font-size: 36px;
    }
    .actionButton {
      @include buttons;
      background-color: $e-dark;
      color: white;
      cursor: pointer;
    }
    .actionButton:hover {
      background-color: $e-dark-hover;
    }
    .actionButton:focus {
      outline: solid $e-light 2px;
    }
  }
  .image {
    width: 30%;
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
}

.light {
  background-color: $activity-light;
}

.dark {
  background-color: $activity-dark;
}

@media only screen and (max-width: 480px) {
  .activityContainer {
    display: block;
    .info {
      width: 100%;
    }
    .image {
      display: none;
    }
  }
}
