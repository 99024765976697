@import '../../variable.scss';

.newsContainer {
  .ant-card {
    border-width: 0;
    .ant-card-head {
      background-color: $e-dark;
      color: white;
      .ant-card-head-title {
        text-align: center;
      }
    }
    .ant-card-body {
      height: 350px;
    }
  }
}
@media only screen and (max-width: 480px) {
  .newsContainer {
    .ant-card {
      .ant-card-body {
        height: 400px;
        #twitter-widget-0 {
          height: 360px !important;
        }
      }
    }
  }
}
