@import './variable.scss';

.mainLayoutContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  .fixedHeader {
    position: fixed;
    width: 100%;
    z-index: 9;
  }
  .footerSignature {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $e-dark;
    padding: 5px;
    p {
      color: white;
    }
  }
}
