@import '../../variable.scss';

.navBarContainer {
  background-color: $light;
  display: flex;
  height: 35px;
  .navButton {
    color: black;
    width: 100px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    a {
      color: black;
    }
  }
  .navButton:hover {
    background-color: $e-light;
  }
  .navButton:focus {
    background-color: $e-light;
  }
}

@media only screen and (max-width: 480px) {
  .navBarContainer {
    height: 25px;
    .navButton {
      height: 25px;
      width: 80px;
    }
  }
}
