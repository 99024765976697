// colors
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Great+Vibes&family=Noto+Serif&family=Sacramento&family=Tangerine:wght@700&display=swap');

$font-header: 'Noto Serif', serif;
$font-regular: 'Noto Serif', serif;

$e-light: #f4eea9;
$light: #f0bb62;
$e-dark: #541212;
$e-dark-hover: #771c1c;
$dark: #519259;
$background: #eeedde;

// activity
// $activity-dark: #141e61;
$activity-dark: #a4aad1;
$activity-light: #eeeeee;

// buttons

@mixin buttons {
  width: 120px;
  padding: 8px;
  border-radius: 5px;
  border-width: 0;
}

// common styles
@mixin centralize {
  display: flex;
  justify-content: center;
  align-items: center;
}
