@import '../variable.scss';
.footerContainer {
  background-color: $e-dark;
  display: flex;
  justify-content: space-around;
  padding: 24px;
  .socialPlatformContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-list {
      width: 60%;
      display: flex;
      justify-content: center;
      .socialLink {
        text-decoration: none;
        color: white;
        font-size: 1rem;
        span {
          margin-left: 10px;
        }
        svg {
          width: 1rem;
          height: 1rem;
        }
      }
      .socialLink:focus {
        outline: solid $light 1px;
      }
      .socialLink:hover {
        color: $light;
      }
    }
  }
  .contactFormContainer {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .ant-input {
      width: 300px;
      border-radius: 5px;
      padding: 8px;
    }

    .ant-input-affix-wrapper {
      border-radius: 5px;
    }
    .submitButton {
      width: 300px;
      border-radius: 5px;
      border: 0;
      padding: 8px;
      background-color: $light;
      cursor: pointer;
    }
    .submitButton:hover {
      background-color: $e-light;
    }
    .submitButton:focus {
      outline: solid $e-light 2px;
    }
  }

  .footerTitle {
    font-size: 30px;
    color: antiquewhite;
  }
}

@media only screen and (max-width: 480px) {
  .footerContainer {
    display: block;
    padding: 8px;
    .socialPlatformContainer {
      width: 100%;
      margin-bottom: 20px;
    .ant-list {
      .socialLink {
        text-decoration: none;
        color: white;
        font-size: 0.8rem;
        span {
          margin-left: 8px;
        }
        svg {
          width: 0.8rem;
          height: 0.8rem;
        }
      }
    }
    }
    .contactFormContainer {
      width: 100%;
      .ant-input {
        border-radius: 3px;
        padding: 4px;
      }
      .ant-input-affix-wrapper {
        border-radius: 3px;
      }
      .submitButton {
        border-radius: 3px;
        border: 0;
        padding: 4px;
        background-color: $e-light;
      }
    }
    .footerTitle {
      font-size: 20px;
    }
  }
}
